import Paymentez from './Paymentez.vue';

export default {
  component: Paymentez,
  settingsSchema: {
    fields: [{
        type: 'text',
        label: 'URL',
        model: 'url',
      },
      {
        type: 'text',
        label: 'API Login Server',
        model: 'apiLogin',
      },
      {
        type: 'text',
        label: 'API Key Server',
        model: 'apiKey',
      },
      {
        type: 'text',
        label: 'API Login Client',
        model: 'apiLoginClient',
      },
      {
        type: 'text',
        label: 'API Key Client',
        model: 'apiKeyClient',
      },
      {
        type: 'text',
        label: 'Métodos de pago',
        model: 'allowPaymentMethods',
      },
      {
        type: 'number',
        label: 'Tiempo de expiración de enlace',
        model: 'expirationTime',
      },
    ]
  }
}